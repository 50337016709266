<template>
  <div class="vg_wrapper">
    
    <el-card>
     <div class="vd_search_group">
       <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getCpttsNow()">
         <el-row>
           <el-col :md="8">
             <el-form-item label="公司编号：">
               <el-input size="small" v-model.trim="searchForm.cptt_no" clearable placeholder="请填写公司编号"></el-input>
             </el-form-item>
           </el-col>
           <el-col :md="8">
             <el-form-item label="公司抬头：">  
               <el-input size="small"  v-model="searchForm.cptt_name"  clearable placeholder="请填写公司抬头"></el-input>
             </el-form-item>
           </el-col>
           <el-col :md="8">
             <el-form-item label="公司类型：">
               <el-select size="small" v-model.trim="searchForm.cptt_type" placeholder="请选择公司类型" clearable @visible-change="getCpttType($event)">
                 <el-option
                     v-for="item in cpttTypeGroupOpt"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
               </el-select>
             </el-form-item>
           </el-col>
           
         </el-row>
         <el-row>
           <el-col :md="8">
             <el-form-item label="单据状态：">
               <selectStatus ref="selectStatus" @selectStatusRow="getStatusVal"></selectStatus>
             </el-form-item>
           </el-col>
           <el-col :md='14'>
             <el-form-item label="录入时间：">
               <div class="block">
                 <el-date-picker
                     size="small"
                     v-model="searchForm.timeValue"
                     type="datetimerange"
                     range-separator="至"
                     start-placeholder="开始日期"
                     end-placeholder="结束日期">
                 </el-date-picker>
               </div>
             </el-form-item>
             <el-form-item>
               <el-button size="small" type="primary" icon="el-icon-search" @click="getCpttsNow()" class="vg_ml_16">查询</el-button>
               <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
             </el-form-item>
           </el-col>
         </el-row>
       </el-form>
    </div>
     <div class="vd_button_group vg_mtb_16">
      <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
      <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      
    </div>
     <el-row>
      <el-col :md="24">
        <el-table   v-loading="isLoding" ref="multiTable" class="vg_pointer" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
          <el-table-column type="selection" width="48"  align="center"/>
          <el-table-column label="公司编号" prop="cptt_no"/>
          <el-table-column label="公司简称" prop="cptt_abbr" show-overflow-tooltip/>
          <el-table-column label="公司抬头" show-overflow-tooltip>
            <template slot-scope="scope">
                <span v-if="scope.row.cptt_name">{{scope.row.cptt_name}}</span>
                <span v-else  class="vg_notava">暂无公司简称</span>
              </template>
          </el-table-column>
          <el-table-column label="公司类型" prop="cptt_type" :formatter="formatCpttType"/>
          <el-table-column label="公司地址" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.cptt_address">{{scope.row.cptt_address}}</span>
              <span v-else class="vg_notava">暂无地址</span>
            </template> 
          </el-table-column>
          <el-table-column label="公司电话" prop="cptt_phone">
            <template slot-scope="scope">
              <span v-if="scope.row.cptt_phone">{{scope.row.cptt_phone}}</span>
              <span v-else class="vg_notava">暂无电话</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="所属账套" prop="acct_no" v-if="isAcct"/> -->
          <!-- <el-table-column label="捆绑账套" prop="cptt_acct_no" v-if="isAcct"/> -->
          <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="personCombina"/>
          <el-table-column label="录入时间" prop="create_time" :formatter="formatDate"/>
          <el-table-column label="单据状态" :show-overflow-tooltip='true' align="center">
            <template slot-scope="scope">
              <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
     <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {cpttAPI} from "@api/modules/comptitle"
import selectStatus from "@/views/component/selectStatus";
import pubPagination from "@/components/common/pubPagination";
import options from "@/views/component/common/options";
export default {
  name: 'CpttList',
  components: {
    selectStatus,
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        cptt_no: '',
        cptt_name: '',
        cptt_type:null,
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      cpttTypeGroupOpt:[],
      btn:{},
      isAcct:false,
      isLoding:true
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/cptt_add' || from.path === '/cptt_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.getCptts();
      if(this.$cookies.get('userInfo').acct_id === 1){
        this.isAcct = true
      }
    },
    // 获取公司抬头信息
    getCptts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(cpttAPI.getCptts,
          {
            cptt_no: this.searchForm.cptt_no,
            cptt_name: this.searchForm.cptt_name,
            cptt_type: this.searchForm.cptt_type,
            status: this.searchForm.status,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            page_no: this.currentPage,
          })
      .then(res => {
        if(res.data.code === 0){
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total
          this.btn = res.data.data.btn;
          setTimeout(()=>{
            this.isLoding = false 
          },500)
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        cptt_no: '',
        cptt_name: '',
        cptt_type:null,
        status:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.isLoding = true
      this.$refs.selectStatus.changeValue()
      this.initData()
    },
    // 获取公司类型
    getCpttType(flag){
      if(flag === true && this.cpttTypeGroupOpt.length === 0){
        this.cpttTypeGroupOpt = options.cpttOpts
      }
    },
    //公司类型
    formatCpttType(row){
      if(row.cptt_type === 1){
        return '加工厂'
      } else if(row.cptt_type === 2){
        return '面料厂'
      } else if(row.cptt_type === 3){
        return '配套厂'
      } else if(row.cptt_type === 4){
        return '辅料厂'
      }else if(row.cptt_type === 5){
        return '包材厂'
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 查询方法
    getCpttsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.isLoding = true
      this.getCptts()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.isLoding = true
      this.getCptts()
    },
    // 多选获取公司抬头信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 录入人
    personCombina(scope) {
    let stffName = scope.stff_name;
    let deptName = scope.dept_name;
    let deptTeamName = scope.dept_team_name;
    let cpttName = scope.enter_cptt_abbr;
    if (stffName === null) {
      stffName = ''
    }
    if (deptName === null) {
      deptName = ''
    }
    if (deptTeamName === null) {
      deptTeamName = ''
    }
    if (cpttName === null) {
      cpttName = ''
    }
    let combination = stffName + '('+ cpttName + ' '  + deptName + ' ' + deptTeamName + ')';
    return combination
  },
    // 新增
    addRow(){
      const permId = this.$route.query.perm_id
      this.jump(`/cptt_add?perm_id=${permId}`)
    },
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    //删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.cptt_id)
      })
      post(cpttAPI.deletCpttByIds,{'cptt_id_list':ids})
      .then(res=>{
        if(res.data.code === 0){
          let mg = res.data.msg;
          let tp = 'success';
          this.$message({message:mg,type:tp});
          if(ids.length === this.tableData.length){
              if(this.currentPage > 1){
                this.currentPage = this.currentPage -1
                this.$refs.pubPagination.currentPage = this.currentPage
              }
            }
          this.initData()
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
          this.initData()
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
        this.initData()
      })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/cptt_edit',{perm_id:permId,form_id:row.cptt_id})
    }
  }
};
</script>

<style scoped lang="scss">

</style>